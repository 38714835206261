import React from "react";
import { IoIosArrowDropright } from "react-icons/io";
import { Link } from "gatsby";
import { useIntl } from "react-intl";
import { defaultLanguage } from "../../i18n";

import PostItem from "./PostItem";

const PostsList = ({ posts, category, categorySlug, limit }) => {
  const recentPosts = limit ? posts.slice(0, limit) : posts;
  const intl = useIntl();
  const locale =
    defaultLanguage === intl.locale
      ? `${process.env.GATSBY_DEFAULT_LANG}`
      : `${intl.locale}`;
  let showAllSlug =
    process.env.GATSBY_MULTILINGUAL === "true" ? `/${locale}/` : `/`;
  if (categorySlug) {
    showAllSlug =
      process.env.GATSBY_MULTILINGUAL === "true"
        ? `/${locale}/category/${categorySlug}`
        : `/category/${categorySlug}/`;
  }

  return (
    <div className="posts-list">
      {category && (
        <div className="posts-list__category-name">
          <h2 className="posts-list__title">{category}</h2>
          <div className="posts-list__seperator"></div>
          <div className="posts-list__link">
            <Link to={showAllSlug}>
              <span className="posts-list__custom-link-text">Show all</span>
              <span className="posts-list__custom-link-icon">
                <IoIosArrowDropright />
              </span>
            </Link>
          </div>
        </div>
      )}
      <ul className="posts-list__list">
        {recentPosts.map((post) => (
          <li key={post.id} className="posts-list__list-item">
            <PostItem
              postContent={post}
              category={post.categories?.nodes[0].name}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PostsList;
